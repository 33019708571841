<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col xs="12" md="6">
          <h1>Login</h1>
          <br />

          <p>
            Please enter in your login credentials. If you want to become a
            lender, please contact us to get onboarded.
          </p>
        </v-col>
      </v-row>

      <v-form @submit.prevent="submit">
        <v-row justify="center">
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              label="email"
              placeholder="Email"
              outlined
              v-model="email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row justify="center">
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              label="password"
              placeholder="password"
              outlined
              v-model="password"
              type="password"
              v-on:keyup.enter="submit"
            ></v-text-field>
            <!-- <span style="cursor: pointer; font-size: 14px; position: absolute; margin-top: -20px"  @click="$router.push({path: `/myAccount/reset-password`})">Forgot Pasword</span> -->
          </v-col>
        </v-row>

        <v-row v-if="sending && !errors">
          <v-col cols="12" sm="12" md="6">
            <p>Logging in...</p>
          </v-col>
        </v-row>

        <v-row justify="center" v-if="errors">
          <v-col cols="12" sm="12" md="6">
            <v-alert v-if="errors.length" type="error">
              <ul>
                <li v-for="(error, index) in errors" :key="index">
                  {{ error }}
                </li>
              </ul>
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-if="!sending" justify="center">
          <v-col cols="12" sm="12" md="6">
            <v-checkbox v-model="isRemembered" label="Remember me" />

            <v-btn @click="submit"> Login </v-btn>

            <span
              style="
                cursor: pointer;
                font-size: 14px;
                margin-left: 20px;
                margin-top: 50px;
              "
              @click="$router.push({ path: `/myAccount/reset-password` })"
            >
              Forgot Pasword
            </span>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import API from '@/plugins/API';

export default {
  data() {
    return {
      email: '',
      password: '',
      hideText: false,
      sending: false,
      isRemembered: false,
      errors: [],
    };
  },
  methods: {
    async submit() {
      try {
        this.errors = [];
        this.sending = true;
        let loginResult = await API().post('/api/auth/local', {
          identifier: this.email,
          password: this.password,
        });
        console.log('loginResult is:');
        console.log(loginResult);

        if (loginResult.status === 200) {
          await this.$store.dispatch('Auth/jwt_login', loginResult.data);

          this.$router.push('/dashboard/mortgagesAvailable/list');
        } else {
          this.sending = false;
          this.errors.push('There was an error logging in, please try again.');
        }

        if (this.isRemembered) {
          localStorage.setItem('isRemembered', this.isRemembered);
        } else {
          localStorage.setItem('isRemembered', this.isRemembered);
        }
      } catch (error) {
        console.log(error);
        this.errors.push(
          'There was an error logging in, please check your information and try again.'
        );
        this.sending = false;
      }
    },
  },
};
</script>

<style></style>
